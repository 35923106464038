<template>
  <section class="tile information--names">
    <h2>Name Configuration</h2>
    <label>
      Messy Names
      <textarea v-model="input" rows="5" />
    </label>
    <h3>Name Configuration</h3>
    <p>Set how the names appear in the data pasted above</p>
    <draggable v-model="nameOrder" class="information--names-order">
      <transition-group>
        <div v-for="opt in nameOrder" :key="opt" class="name-item pointer">
          <font-awesome-icon icon="grip-vertical" size="sm" class="mr-10" />
          {{ opt }}
        </div>
      </transition-group>
    </draggable>
    <div class="information--names-display">
      <div class="name-list">
        <p class="text__bold">First</p>
        <textarea :value="firstNames" />
      </div>
      <div class="name-list">
        <p class="text__bold">Last</p>
        <textarea :value="lastNames" />
      </div>
      <div class="name-list">
        <p class="text__bold">Full</p>
        <textarea :value="fullNames" />
      </div>
    </div>
  </section>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable
  },
  data() {
    return {
      input: null,
      nameOrder: ["Last", "First"]
    };
  },
  computed: {
    output() {
      if (!this.input) return null;
      const names = this.input
        .replaceAll(", ", " ")
        .split("\n")
        .map(name => name.split(" "));
      const namesSplit = names.map(name => {
        if (this.nameOrder[0] === "First") {
          return {
            first: name.slice(0, name.length - 1).join(" "),
            last: name[name.length - 1],
            full: `${name.slice(0, name.length - 1).join(" ")} ${
              name[name.length]
            }`
          };
        }
        return {
          first: name.slice(1, name.length).join(" "),
          last: name[0],
          full: `${name.slice(1, name.length).join(" ")} ${name[0]}`
        };
      });
      return namesSplit;
    },
    firstNames() {
      if (!this.input) return null;
      return this.output.map(item => item.first).join("\n");
    },
    lastNames() {
      if (!this.input) return null;
      return this.output.map(item => item.last).join("\n");
    },
    fullNames() {
      if (!this.input) return null;
      return this.output.map(item => item.full).join("\n");
    }
  }
};
</script>

<style scoped lang="scss">
textarea {
  height: 10rem;
}
.information--names-order span {
  @include flex($just: flex-start, $align: center);
}
.name-item {
  padding: 5px 8px;
  margin: 8px;
  border-radius: 5px;
  font-weight: bold;
  background-color: $powder;
}
.information--names-display {
  @include grid($cols: 1fr 1fr 1fr, $col-gap: 5px);
}
</style>