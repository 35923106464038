<template>
  <div class="information--category-search mb-15">
    <h3 class="mb-15">Existing Categories</h3>
    <div class="search--bar">
      <input type="text" v-model="search" placeholder="search text" @input="emitSearch" />
      <button class="btn__search" @click="emitSearch">
        <font-awesome-icon icon="search" size="lg" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: ""
    };
  },
  methods: {
    emitSearch() {
      this.$emit("search", this.search);
      // this.search = "";
    }
  }
};
</script>

<style scoped lang="scss">
/* .information--category-search {
  @include grid($cols: 3fr 1fr, $align: center);
  h3 {
    grid-column: 1 / span 2;
  }
} */
</style>