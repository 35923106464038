<template>
  <section class="information-header">
    <div class="container">
      <router-link to="/admin" class="breadcrumb">
        <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />Back to Admin
      </router-link>
      <h1 class="text__center padding__sm">Site Setup</h1>
      <p
        class="header--message"
      >Content entered below will appear in several spots across the website. When it is created it will immediately appear so be careful what is entered.</p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
</style>