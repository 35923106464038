<template>
  <section class="information">
    <Header />
    <div class="container">
      <Notifications :notes="getNotes" />
      <div class="information__grid margin__md">
        <Categories />
        <NameConfig />
      </div>
      <div class="information__grid margin__md">
        <HomeTileOne :tile="getHomeTiles[0]" />
        <HomeTileTwo :tile="getHomeTiles[1]" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./Header";
import Notifications from "./Notifications";
import Categories from "./Categories";
import NameConfig from "./NameConfig";
import HomeTileOne from "./HomeTileOne";
import HomeTileTwo from "./HomeTileTwo";
export default {
  components: {
    Header,
    Notifications,
    Categories,
    NameConfig,
    HomeTileOne,
    HomeTileTwo
  },
  metaInfo: {
    title: "Site Setup | WOW"
  },
  computed: {
    ...mapGetters(["getNotes", "getHomeTiles"])
  }
};
</script>

<style scoped lang="scss">
.information__grid {
  @include grid($cols: 1fr, $row-gap: 50px, $col-gap: 25px);
}
@media (min-width: $md) {
  .information__grid {
    grid-template-columns: 1fr 1fr;
  }
}
</style>