<template>
  <section class="information--categories tile">
    <h2>Categories</h2>
    <p>The following categories are available to be assigned to products and will be options when filtering products</p>
    <div class="m-tb-sm">
      <div class="information--categories--new">
        <input type="text" v-model="form.name" placeholder="new category" @keypress.enter="submit" />
        <button class="btn__green" @click="submit">Create</button>
      </div>
    </div>
    <Search @search="filterCategories" />
    <simplebar class="categories--overflow" data-simplebar-auto-hide="false">
      <div
        v-if="filteredCategories.length <= 0"
        class="flex__center information--categories__default"
      >
        <p class="text__bold text__center">No categories found</p>
      </div>
      <div v-else class="information--categories__grid">
        <Category v-for="category in filteredCategories" :key="category._id" :category="category" />
      </div>
    </simplebar>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Search from "./Search";
import Category from "./Category";
export default {
  components: {
    Category,
    Search
  },
  data() {
    return {
      form: {
        name: ""
      },
      search: ""
    };
  },
  computed: {
    ...mapGetters(["getCategories"]),
    filteredCategories() {
      if (this.search === "") {
        return this.getCategories;
      } else {
        return this.getCategories.filter(category => {
          return category.name.toLowerCase().includes(this.search);
        });
      }
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startCreateCategory"]),
    async submit() {
      if (this.form.name !== "") {
        this.form.name = this.form.name.toLowerCase();
        this.startSetIsSubmitting({ bool: true, heading: "Sending" });
        try {
          await this.startCreateCategory(this.form);
          this.form.name = "";
          this.startSetIsSubmitting({ bool: false });
        } catch (err) {
          this.startSetIsSubmitting({ bool: false });
        }
      }
    },
    filterCategories(val) {
      this.search = val.toLowerCase();
    }
  }
};
</script>

<style scoped lang="scss">
.information--categories--new {
  @include grid($cols: 3fr 1fr, $align: center);
  button {
    border: none;
    height: 100%;
  }
}
.categories--overflow {
  max-height: 300px;
}
.information--categories__default {
  height: 200px;
  background-color: $frost;
}
.information--categories__grid {
  @include grid($cols: 1fr, $row-gap: 10px, $col-gap: 15px);
}
</style>