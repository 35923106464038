<template>
  <section class="information-messages margin__md tile">
    <div class="flex__between">
      <h2>Notifications</h2>
      <button v-if="!editing" class="btn btn__sm btn__red" @click="toggleEditing">Edit</button>
      <button v-else class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
    </div>
    <p
      v-if="form.productNotes === ''"
      class="form-error--message margin__sm"
    >You must enter something for the product notes.</p>
    <div class="notification margin__sm">
      <p>This will appear below the dark bar at the top of the screen. Leave it blank if you don't want something to appear.</p>
      <div class="information-messages__grid">
        <p class="warning-label">Warning Message</p>
        <p v-if="!editing" class="warning-content">{{ notes.notification }}</p>
        <textarea v-else class="warning-input" v-model="form.notification" rows="5" />
      </div>
    </div>
    <div class="notification margin__sm">
      <p>This will appear on the products page and every product page. Cannot be blank.</p>
      <div class="information-messages__grid">
        <p class="notes-label">Product Notes</p>
        <p v-if="!editing" class="notes-content pre-wrap">{{ notes.productNotes }}</p>
        <textarea v-else class="notes-input" v-model="form.productNotes" rows="5" />
      </div>
    </div>
    <div v-if="editing" class="flex__between">
      <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Done</button>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["notes"],
  data() {
    return {
      editing: false,
      form: {
        _id: this.notes ? this.notes._id : null,
        notification: this.notes ? this.notes.notification : "",
        productNotes: this.notes ? this.notes.productNotes : ""
      }
    };
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateNotes"]),
    toggleEditing() {
      this.editing = !this.editing;
    },
    async submit() {
      if (this.form.productNotes === "") {
        return;
      }
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      try {
        await this.startUpdateNotes(this.form);
        this.startSetIsSubmitting({ bool: false });
        this.toggleEditing();
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.information-messages__grid {
  @include grid($cols: 1fr, $rows: 40px 1fr, $col-gap: 15px, $row-gap: 15px);
}
.warning-label,
.warning-content,
.notes-label,
.notes-content {
  color: #fff;
  border-radius: 3px;
}
.warning-label,
.warning-content {
  background-color: $maroon;
}
.warning-label,
.notes-label {
  padding: 5px;
}
.warning-content,
.warning-input,
.notes-content,
.notes-input {
  padding: 10px 5px;
}
.notes-label,
.notes-content {
  background-color: $orange;
}
@media (min-width: $md) {
  .information-messages__grid {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
  }
}
</style>