<template>
  <section class="information--hometile tile">
    <h2>Home Page Tile Two</h2>
    <p>Information entered below is used to create a dynamic tile on the home page. Changes you make are live immediately, so be careful what you enter. You can turn off the tile by clicking "Hide Tile".</p>
    <div class="hometile-content">
      <label :class="[errors.name ? 'form--error' : null]">
        <span class="text__bold">Name{{ editing ? "*" : null }}</span>
        <span v-if="!editing">{{ tile && tile.name ? tile.name : "None provided" }}</span>
        <input v-else type="text" v-model="form.name" placeholder="tile name" />
      </label>
      <form-error v-if="editing" :message="errors.name" />
      <div class="hometile-content--products">
        <p class="text__bold">Products</p>
        <div v-if="!editing && (!tile || !tile.products)" class="padding__md flex__center">
          <p class="text__bold text__center">No products selected</p>
        </div>
        <ul v-if="!editing && tile && tile.products" class="products-editing--select">
          <li
            v-for="product in tile.products"
            :key="product._id"
            class="text__overflowl"
          >&bull;&nbsp;&nbsp;{{ getProductName(product) }}</li>
        </ul>
        <div v-if="editing" class="products-editing">
          <div class="search--bar">
            <input type="text" v-model="search" placeholder="product search" />
            <button class="btn__search">
              <font-awesome-icon icon="search" size="lg" />
            </button>
          </div>
          <simplebar class="minor--overflow" data-simplebar-auto-hide="false">
            <div
              v-if="!filteredProducts || filteredProducts.length <= 0"
              class="flex__center bg-light-grey"
            >
              <p class="text__center">no results</p>
            </div>
            <ul v-else class="products-editing--select">
              <label v-for="product in filteredProducts" :key="product._id">
                <li>
                  <input type="checkbox" :value="product._id" v-model="form.products" />
                  <span class="text__overflow">{{ product.name }}</span>
                </li>
              </label>
            </ul>
          </simplebar>
          <form-error v-if="editing" :message="errors.products" />
          <p class="text__bold">Product Order</p>
          <div
            v-if="!form.products || form.products.length <= 0"
            class="flex__center padding__sm bg-light-grey"
          >
            <p class="text__center">select a product</p>
          </div>
          <draggable v-else v-model="form.products">
            <transition-group>
              <p class="products-editing--order" v-for="product in form.products" :key="product">
                <font-awesome-icon icon="list" size="sm" class="mr-10" />
                <span class="text__overflow">{{ getProductName(product) }}</span>
              </p>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
    <div v-if="!editing" class="flex__between mt-15">
      <button
        class="btn btn__sm btn__frost"
        @click="toggleTile"
      >{{ form.status ? "Hide Tile" : "Show Tile"}}</button>
      <button class="btn btn__sm btn__green" @click="toggleEditing">Edit</button>
    </div>
    <div v-else class="flex__between mt-15">
      <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Done</button>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import draggable from "vuedraggable";
//when showing this one home page filter the selected products to ensure they are live
export default {
  props: ["tile"],
  components: {
    draggable
  },
  data() {
    return {
      editing: false,
      isValidated: true,
      search: "",
      form: {
        _id: this.tile ? this.tile._id : null,
        name: this.tile ? this.tile.name : null,
        products: this.tile ? this.tile.products : [],
        status: this.tile ? this.tile.status : true
      },
      errors: {
        name: null,
        products: null
      }
    };
  },
  computed: {
    ...mapGetters(["getProducts"]),
    filteredProducts() {
      if (!this.search)
        return this.getProducts.filter(item => {
          return item.status !== "test";
        });
      return this.getProducts.filter(item => {
        return (
          (item.name.toLowerCase().includes(this.search.toLowerCase()) ||
            item.tags.join(", ").includes(this.search.toLowerCase())) &&
          item.status !== "test"
        );
      });
    }
  },
  methods: {
    ...mapActions([
      "startSetIsSubmitting",
      "startCreateHomeTile",
      "startUpdateHomeTile"
    ]),
    toggleEditing() {
      this.editing = !this.editing;
    },
    getProductName(id) {
      return this.getProducts.filter(item => {
        return item._id === id;
      })[0].name;
    },
    async submit() {
      this.validateForm();
      if (!this.isValidated) return;
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      try {
        // await this.startUpdateHomeTile(this.form);
        await this.startCreateHomeTile(this.form);
        this.toggleEditing();
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    validateForm() {
      this.isValidated = true;
      this.errors.name = !this.form.name
        ? "Please enter a name for the tile"
        : null;
      this.errors.products =
        !this.form.products || this.form.products.length <= 0
          ? "Please select at least one product"
          : null;
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    },
    async toggleTile() {
      this.startSetIsSubmitting({ bool: true, heading: "Hiding" });
      let form = {
        id: this.form._id,
        status: this.form.status ? false : true
      };
      try {
        await this.startUpdateHomeTile(form);
        this.form.status = form.status;
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.hometile-content {
  @include grid($cols: 1fr, $row-gap: 8px);
  label {
    @include grid($cols: 1fr, $row-gap: 3px);
  }
}
.products-editing {
  @include grid($cols: 1fr, $row-gap: 8px);
}
.minor--overflow {
  height: 175px;
  border: 1px solid $black;
  .flex__center {
    height: 175px;
  }
}
.products-editing--select {
  @include grid($cols: 1fr, $row-gap: 3px);
  padding: 10px;
  label li {
    @include grid($cols: 1fr 7fr, $col-gap: 5px, $align: center);
  }
}
.products-editing--order {
  @include grid($cols: 1fr 7fr, $col-gap: 3px, $align: center);
  margin: 3px;
}
</style>