<template>
  <div class="information--categories-item">
    <p v-if="!editing" class="text__center">{{ category.name }}</p>
    <input
      v-else
      type="text"
      v-model="form.name"
      placeholder="category name"
      @keypress.enter="submit"
      ref="category"
    />
    <div v-if="!editing && !deleting" class="dual--buttons">
      <div class="btn__red flex__center" @click="toggleDeleting">
        <font-awesome-icon icon="times" size="1x" class="mr-10" />Delete
      </div>
      <div class="btn__green flex__center" @click="toggleEditing">
        <font-awesome-icon icon="edit" size="1x" class="mr-10" />Edit
      </div>
    </div>
    <div v-if="deleting" class="dual--buttons">
      <div class="btn__red flex__center" @click="toggleDeleting">No, Go Back</div>
      <div class="btn__green flex__center" @click="deleteCategory">Delete</div>
    </div>
    <div v-if="editing" class="dual--buttons">
      <div class="btn__red flex__center" @click="toggleEditing">Cancel</div>
      <div class="btn__green flex__center" @click="submit">Done</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { createError } from "../../../libs/flash-messages";
export default {
  props: ["category"],
  data() {
    return {
      editing: false,
      deleting: false,
      form: {
        _id: this.category ? this.category._id : "",
        name: this.category ? this.category.name : ""
      }
    };
  },
  methods: {
    ...mapActions([
      "startSetIsSubmitting",
      "startUpdateCategory",
      "startDeleteCategory"
    ]),
    toggleEditing() {
      this.editing = !this.editing;
      if (this.editing === true) {
        this.$nextTick(() => {
          this.$refs.category.focus();
        });
      }
    },
    toggleDeleting() {
      this.deleting = !this.deleting;
    },
    async submit() {
      if (this.form.name === "") {
        createError("Category Error", "You must enter a category name");
        return;
      }
      this.form.name = this.form.name.toLowerCase();
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      try {
        await this.startUpdateCategory(this.form);
        this.toggleEditing();
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    async deleteCategory() {
      this.startSetIsSubmitting({ bool: true, heading: "Deleting" });
      try {
        await this.startDeleteCategory(this.form);
        this.toggleDeleting();
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.information--categories-item {
  @include grid($cols: 1fr, $rows: 1fr auto, $align: center);
  p {
    border: 1px solid $black;
    border-radius: 3px;
    padding: 5px;
  }
}
@media (min-width: $sm) {
  .information--categories-item {
    grid-template-columns: 3fr 2fr;
  }
}
@media (min-width: $md) {
  .information--categories-item {
    grid-template-columns: 1fr;
  }
}
@media (min-width: $xl) {
  .information--categories-item {
    grid-template-columns: 3fr 2fr;
  }
}
</style>